import React, { FC } from "react";

export type Alignment = "top" | "center";

type Props = {
  color?: string;
  size?: string | number;
  align?: Alignment;
};

const CloudoguLogo: FC<Props> = ({ size, color, align }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox={`0 ${align === "top" ? 650 : 0} 3465.000000 2123.000000`}
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,2123.000000) scale(0.100000,-0.100000)">
      <path
        d="M17065 21224 c-22 -2 -121 -11 -220 -20 -875 -77 -1791 -332 -2585
-720 -278 -136 -453 -230 -630 -338 -1994 -1213 -3321 -3243 -3610 -5521 -11
-88 -23 -179 -26 -202 -3 -27 -10 -43 -19 -43 -7 0 -82 26 -167 59 -408 155
-793 266 -1223 351 -828 163 -1608 183 -2420 64 -1729 -254 -3300 -1124 -4427
-2449 -325 -383 -621 -820 -863 -1273 -488 -916 -775 -1906 -860 -2965 -20
-254 -20 -851 0 -1104 62 -771 222 -1477 494 -2179 267 -692 597 -1272 1032
-1814 995 -1240 2478 -2205 4186 -2721 540 -163 1109 -271 1743 -331 109 -11
1872 -12 9100 -10 4931 2 9318 8 9750 12 693 7 808 11 980 29 1440 156 2918
705 4165 1548 1076 728 1890 1620 2389 2621 454 909 719 1899 787 2945 13 204
7 854 -11 1037 -80 858 -268 1609 -590 2360 -80 187 -296 615 -401 795 -660
1131 -1623 2066 -2767 2684 -877 474 -1785 749 -2807 848 -227 22 -934 25
-1150 5 -746 -69 -1351 -204 -2013 -448 -94 -35 -178 -64 -186 -64 -10 0 -15
25 -20 98 -68 995 -315 1913 -748 2782 -167 336 -259 497 -433 761 -557 839
-1264 1536 -2112 2081 -424 272 -978 544 -1448 710 -561 199 -1102 321 -1725
389 -166 18 -265 22 -660 24 -256 1 -483 1 -505 -1z m860 -2744 c334 -37 685
-114 990 -215 1193 -398 2184 -1307 2683 -2460 186 -430 293 -840 354 -1360
21 -174 17 -811 -5 -1000 -53 -444 -168 -901 -322 -1276 -25 -61 -45 -122 -45
-138 0 -30 -8 -42 -178 -278 -136 -188 -287 -421 -398 -613 -178 -309 -423
-826 -408 -863 3 -8 92 -52 197 -98 673 -290 1348 -719 1877 -1190 120 -107
192 -159 221 -159 20 0 16 -8 81 178 293 846 848 1607 1593 2187 124 96 369
259 535 355 538 310 1160 511 1815 587 186 21 757 24 935 5 302 -33 543 -80
810 -157 902 -260 1689 -787 2278 -1523 504 -631 841 -1414 956 -2222 39 -276
49 -454 43 -791 -8 -486 -57 -813 -189 -1263 -170 -581 -442 -1109 -796 -1546
-189 -233 -526 -543 -786 -725 -739 -515 -1731 -830 -2961 -939 -400 -36 -473
-37 -3537 -36 -1670 0 -3048 -2 -3061 -5 -17 -5 -37 -31 -73 -98 -256 -475
-738 -946 -1322 -1291 -535 -316 -1126 -516 -1707 -577 -178 -19 -598 -16
-775 5 -814 97 -1593 457 -2226 1026 -270 243 -488 513 -657 813 -41 74 -68
112 -85 118 -18 7 -4493 8 -5542 1 -444 -3 -1236 52 -1680 118 -470 69 -897
177 -1250 317 -679 268 -1205 687 -1650 1313 -168 236 -407 663 -530 945 -533
1222 -543 2587 -30 3801 380 895 1051 1656 1890 2144 564 327 1174 524 1840
592 188 19 717 16 910 -5 772 -86 1492 -348 2130 -777 488 -328 963 -802 1289
-1288 215 -321 377 -633 492 -952 27 -74 57 -154 65 -177 9 -24 22 -43 31 -43
8 0 69 53 136 118 530 510 1154 949 1807 1268 129 63 251 123 270 132 30 15
35 22 33 51 -2 54 -225 495 -385 765 -106 177 -321 495 -466 688 -152 204
-162 223 -237 477 -130 438 -183 787 -192 1251 -9 479 30 836 137 1257 204
799 607 1515 1193 2119 552 570 1208 981 1948 1222 384 125 738 194 1154 226
100 7 703 -3 800 -14z"
      />
      <path
        d="M17230 16199 c-611 -68 -1151 -322 -1571 -739 -221 -220 -366 -422
-508 -710 -178 -361 -265 -726 -265 -1120 0 -381 85 -745 253 -1087 194 -394
465 -704 826 -949 200 -135 556 -289 785 -339 41 -10 80 -22 87 -29 9 -8 10
-415 3 -1759 -4 -962 -10 -1751 -14 -1754 -3 -3 -72 -20 -153 -38 -548 -121
-1018 -378 -1409 -769 -211 -211 -363 -416 -499 -673 -38 -73 -74 -134 -80
-136 -8 -4 -4540 1135 -4669 1173 l-39 12 6 79 c12 176 -24 485 -83 715 -85
328 -266 688 -485 964 -89 112 -267 292 -380 383 -593 478 -1330 669 -2047
531 -644 -124 -1204 -500 -1571 -1054 -150 -226 -292 -563 -351 -830 -48 -221
-60 -331 -60 -555 0 -221 11 -329 59 -550 102 -473 359 -942 704 -1285 545
-541 1322 -818 2046 -729 384 47 748 180 1064 389 254 168 462 372 644 633 60
85 86 136 131 251 31 79 63 147 70 149 15 6 4670 -1165 4696 -1181 12 -8 15
-50 16 -263 1 -267 9 -355 60 -603 82 -406 293 -848 570 -1196 87 -109 305
-327 414 -414 282 -224 633 -410 968 -512 131 -39 357 -87 507 -105 129 -17
481 -17 610 0 549 69 1040 274 1465 611 124 98 328 300 424 420 268 335 471
753 561 1155 53 236 67 363 70 615 2 188 5 236 16 242 21 12 4848 1228 4874
1228 25 0 42 -33 113 -216 81 -210 330 -516 587 -720 145 -114 273 -194 457
-284 338 -165 673 -243 1043 -243 403 -1 797 97 1163 289 800 420 1324 1237
1381 2152 15 242 -13 522 -79 778 -110 427 -373 878 -681 1169 -441 415 -1003
651 -1599 672 -410 14 -808 -76 -1201 -273 -505 -252 -892 -645 -1148 -1164
-172 -348 -254 -677 -268 -1072 -5 -149 -9 -178 -22 -183 -45 -18 -4845 -1226
-4859 -1223 -10 2 -50 67 -97 158 -256 492 -630 882 -1108 1155 -228 130 -518
244 -761 299 -60 14 -113 29 -118 34 -10 10 10 3563 21 3575 4 4 45 12 92 18
300 37 590 150 871 338 384 257 664 578 873 1003 136 274 225 583 256 887 27
259 -1 592 -72 868 -81 315 -255 659 -466 923 -337 420 -863 739 -1392 845
-234 47 -540 65 -731 44z"
      />
    </g>
  </svg>
);

CloudoguLogo.defaultProps = {
  color: "currentColor",
  size: 24,
  align: "center",
};

export default CloudoguLogo;
